// Notes on the classes:
//
// 1. .carousel.pointer-event should ideally be pan-y (to allow for users to scroll vertically)
//    even when their scroll action started on a carousel, but for compatibility (with Firefox)
//    we're preventing all actions instead
// 2. The .carousel-item-left and .carousel-item-right is used to indicate where
//    the active slide is heading.
// 3. .active.carousel-item is the current slide.
// 4. .active.carousel-item-left and .active.carousel-item-right is the current
//    slide in its in-transition state. Only one of these occurs at a time.
// 5. .carousel-item-next.carousel-item-left and .carousel-item-prev.carousel-item-right
//    is the upcoming slide in transition.

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
  @include clearfix();
}

// add green background on top of slider images to make the text readable
%carousel__backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: transparent linear-gradient(180deg, transparent, var(--primary-color-light), var(--primary-color-dark)) 0%
    0% no-repeat padding-box;
  mix-blend-mode: multiply;
  opacity: 0.7;
  filter: brightness(0.8);
}

.carousel-item {
  position: relative;
  display: none;
  float: right;
  width: 100%;
  margin-left: -100%;
  backface-visibility: hidden;
  @include transition($carousel-transition);

  // use background instead of img element
  background-size: cover;
  background-position: center bottom;
  background-repeat: no-repeat;

  @include media-breakpoint-up("md") {
    // add parallax effect
    background-attachment: fixed;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%);
}

//
// Alternate transitions
//

.carousel-fade {
  .carousel-item {
    opacity: 0;
    transition-property: opacity;
    transform: none;
  }

  .carousel-item.active,
  .carousel-item-next.carousel-item-left,
  .carousel-item-prev.carousel-item-right {
    z-index: 1;
    opacity: 1;
  }

  .active.carousel-item-left,
  .active.carousel-item-right {
    z-index: 1;
    opacity: 0;
    @include transition(opacity 0s $carousel-transition-duration);
  }
}

//
// Left/right controls for nav
//

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  // Use flex for alignment (1-3)
  display: flex; // 1. allow flex styles
  align-items: center; // 2. vertically center contents
  justify-content: center; // 3. horizontally center contents
  width: $carousel-control-width;
  color: $carousel-control-color;
  text-align: center;
  // opacity: $carousel-control-opacity;

  // Hover/focus state
  @include hover-focus() {
    color: $carousel-control-color;
    text-decoration: none;
    outline: 0;

    .carousel-control-svg-icon {
      background-color: $green-light;
      stroke: $primary;
    }
  }
}
.carousel-control-prev {
  left: 0;
  @if $enable-gradients {
    background-image: linear-gradient(
      90deg,
      rgba($black, 0.25),
      rgba($black, 0.001)
    );
  }
}
.carousel-control-next {
  right: 0;
  @if $enable-gradients {
    background-image: linear-gradient(
      270deg,
      rgba($black, 0.25),
      rgba($black, 0.001)
    );
  }
}

// Icons for within
.carousel-control-prev-icon,
.carousel-control-next-icon,
.carousel-control-svg-icon {
  display: inline-block;
  width: $carousel-control-icon-width;
  height: $carousel-control-icon-width;
}
.carousel-control-prev-icon {
  background: no-repeat 50% / 100% 100%;
  background-image: escape-svg($carousel-control-prev-icon-bg);
}
.carousel-control-next-icon {
  background: no-repeat 50% / 100% 100%;
  background-image: escape-svg($carousel-control-next-icon-bg);
}

.carousel-control-svg-icon {
  background-color: rgba($light, 0.71);
  border-radius: $carousel-control-icon-width / 2;
  stroke: white; // for svg icon (defined here for transition)

  @include transition($carousel-control-transition);
  .icon {
    width: 20%;
    height: 100%;
    vertical-align: initial;
  }
}

// Optional indicator pips
//
// Add an ordered list with the following class and add a list item for each
// slide your carousel holds.

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-right: 0; // override <ol> default
  // Use the .carousel-control's width as margin so we don't overlay those
  margin-right: $carousel-control-width;
  margin-left: $carousel-control-width;
  list-style: none;

  li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: $carousel-indicator-width;
    height: $carousel-indicator-width;
    margin-right: $carousel-indicator-spacer;
    margin-left: $carousel-indicator-spacer;
    // increase hit area using padding instead of border so that border-radius could work
    padding: 4px;
    border-radius: ($carousel-indicator-width + 8) / 2; // plus padding * 2
    text-indent: -999px;
    cursor: pointer;
    background-color: $carousel-indicator-active-bg;
    background-clip: content-box;
    // Use transparent borders to increase the hit area by 10px on top and bottom.
    // border-top: $carousel-indicator-hit-area-height solid transparent;
    // border-bottom: $carousel-indicator-hit-area-height solid transparent;
    opacity: 0.5;
    backface-visibility: hidden;
    @include transition($carousel-indicator-transition);
  }

  .active {
    opacity: 1;
    transform: scale(1.3);
  }
}

// Optional captions
//
//

.carousel-caption {
  position: absolute;
  right: (100% - $carousel-caption-width) / 2;
  // center vertically
  top: 50%;
  transform: translateY(-50%);
  left: (100% - $carousel-caption-width) / 2;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: $carousel-caption-color;
  text-align: center;

  h1 {
    margin-top: px-to-rem(22px);
    @include font-size(36px);
    line-height: 1.4;
    font-weight: bold;
  }
  p {
    @include font-size(20px);
    margin-top: px-to-rem(16px);
  }
}

.caption__user-img {
  display: inline-block;
  width: 180px;
  height: 180px;
  border-radius: 50%;
  border: 8px solid $secondary;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  @include media-breakpoint-up("md") {
    width: 220px;
    height: 220px;
  }
}

// carousel--main styles
// this will enable parallax effect and do not show multiple images
.carousel--main {
  background-size: cover;
  background-position: center bottom;
  background-repeat: no-repeat;
  background-attachment: fixed;
  &:after {
    content: "";
    @extend %carousel__backdrop;
  }
  .carousel-item {
    height: 600px;
    z-index: 1;
  }
  @include media-breakpoint-down("sm") {
    background-attachment: scroll;

    .carousel-control-next,
    .carousel-control-prev {
      display: none;
    }
  }
}

.no-webp .carousel--main {
  background-image: url(../images/carousel-bg.jpg);
}
.webp .carousel--main {
  background-image: url(../images/carousel-bg.webp);
}
