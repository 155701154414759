@font-face {
  font-family: "Droid Arabic Kufi";
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: local("Droid Arabic Kufi Regular"),
    url(../fonts/DroidKufiRegular.woff) format("woff");
}

@font-face {
  font-family: "Droid Arabic Kufi";
  font-style: normal;
  font-weight: Bold;
  font-display: swap;
  src: local("Droid Arabic Kufi Bold"),
    url(../fonts/DroidKufiBold.woff) format("woff");
}
