.service {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.service__icon {
  width: $service-icon-width;
  height: $service-icon-width;
  margin-bottom: px-to-rem(20px);
  border-radius: $service-icon-width / 2;
  background: rgba($primary, 0.1);
  overflow: hidden;
}

.service__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.service__title {
  color: var(--primary-color, $primary);
  text-align: center;
  font-weight: bold;
  @include font-size(20px);
}
.service__text {
  // break lines at new lines character
  white-space: pre-line;
}
