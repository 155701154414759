.footer {
  font-size: px-to-rem(14px);
  padding-top: 18px;
  padding-bottom: 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 2px solid $divider-color;
}

.footer__nav {
  a {
    color: lighten($text-secondary, 20);
    & + a {
      margin-right: px-to-rem(30px);
    }
  }

  a.logo {
    img {
      width: auto;
      height: px-to-rem(38px);
    }
    // override + a margin right
    & + a {
      margin-right: px-to-rem(16px);
    }
  }
}

.footer__social {
  position: relative;
  .icon {
    width: 18px;
    height: 18px;
    fill: $text-secondary;
    @include transition(transform 150ms ease);
  }
  a {
    &:hover .icon {
      transform: scale(1.2);
    }

    // spacing
    & + a {
      margin-right: px-to-rem(16px);
    }
  }

  // hide divider if it is a last child in footer social (if there is no social icons)
  div.divider:last-child {
    display: none;
  }
}

// mobile version
@include media-breakpoint-down("md") {
  .footer {
    flex-direction: column;
    text-align: center;
  }
  .footer__nav {
    a + a {
      margin-right: px-to-rem(20px);
    }
  }
  .footer__social {
    margin-top: 20px;

    .divider {
      display: none;
    }
  }

  .footer__copyrights {
    display: inline-block;
    width: 100%;
    margin-bottom: 20px;
  }
}
