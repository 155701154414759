.info {
  background: $light;
  padding: px-to-rem(30px) px-to-rem(22px);
  border-radius: $border-radius;
}

.info__content,
.info__icon {
  display: inline-block;
}

.info__icon {
  vertical-align: top;
  margin-left: px-to-rem(16px);
  width: $info-icon-width;
  height: $info-icon-width;
  fill: var(--primary-color, $primary);
}

.info__title {
  @include font-size(17px);
  color: $secondary;
  margin-bottom: px-to-rem(10px);
}

.info__text {
  text-align: right;
  font-size: $font-size-sm;
  color: $text-secondary;
  margin-bottom: 0;
}

.info__item {
  & + .info__item {
    margin-top: px-to-rem(22px);
  }
}
