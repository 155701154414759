.divider {
  display: inline-block;
  margin-left: 20px;
  margin-right: 20px;
  &:after {
    content: "";
    position: absolute;
    width: 1px;
    top: 2px;
    bottom: 2px;
    background: rgba($text-secondary, 0.4);
  }
}
