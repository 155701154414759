.image-upload {
  width: 240px;
  max-width: 100%;
  height: 200px;
  border: 1px dashed #b2b2b2;
  @include border-radius($border-radius);
  background-image: escape-svg($file-upload);
  background-position: center;
  background-size: 60px 50px;
  background-repeat: no-repeat;
  cursor: pointer;
  position: relative;
  @include transition(
    transform 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out
  );

  &[data-drag="true"] {
    transform: scale(1.05);
  }
  &[data-empty="false"] {
    background-size: cover;
    border-color: #eee;
    border-style: solid;
  }
  &:focus {
    outline: 0;
    box-shadow: $btn-focus-box-shadow;
  }

  > span {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    color: white;
    background: rgba(#000, 0.4);
    text-align: center;
    padding: 9px 4px;
    @include font-size(14px);
    @include border-radius(0 0 $border-radius $border-radius);
  }

  > .loading-icon {
    width: 2rem;
    height: 2rem;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -1rem;
    margin-top: -1rem;
    fill: white;
  }

  @include media-breakpoint-up("lg") {
    width: 100%;
    height: 0;
    // because padding is relative to width not height;
    padding-top: 90%;
  }
}
