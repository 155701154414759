// lawyer card
.lawyer-card {
  background: white;
  @include border-radius($card-border-radius);
  text-align: center;
}

.lawyer-card__img-link {
  display: inline-block;
  width: 140px;
  height: 140px;
  margin-bottom: px-to-rem(18px);
  border: 8px solid lighten($secondary, 38);
  border-radius: (140px / 2);
  overflow: hidden;

  // hover and focus logic
  position: relative;
  &:after {
    content: "";
    pointer-events: none;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-image: escape-svg($external-link);
    background-size: 20px;
    background-repeat: no-repeat;
    background-position: center;
    background-color: rgba($black, 0.2);
    opacity: 0;
    @include transition(opacity 150ms ease-in-out);
  }
  @include hover-focus {
    outline: 0;
    &:after {
      opacity: 1;
    }
  }

  img {
    display: inline-block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}

.lawyer-card__body {
  padding: $card-spacer-y * 1.5 $card-spacer-x;
}

.lawyer-card__title {
  @include font-size(18px);
  // don't take more than one line
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.lawyer-card__subtitle {
  @include font-size(14px);
  color: $text-secondary;
}

.lawyer-card__link {
  padding: $card-spacer-y $card-spacer-x;
  text-align: center;
  color: $body-color;

  // add border radius to links for background color hover effect
  &:first-child {
    @include border-radius(0 0 $card-border-radius 0);
  }
  &:last-child {
    @include border-radius(0 0 0 $card-border-radius);
  }

  @include hover-focus {
    text-decoration: none;
    color: $body-color;
    background-color: rgba($card-border-color, 0.3);
  }

  & + & {
    border-right: 1px solid $card-border-color;
  }

  .icon {
    fill: $primary;
    width: px-to-rem(28px);
    height: px-to-rem(28px);
    padding: 4px;

    &.danger {
      fill: $danger;
    }
  }
}

.lawyer-card__footer {
  display: flex;
  border-top: 1px solid $card-border-color;
  .lawyer-card__link {
    flex: 1;
  }
}

// statistics card
.statistics-card {
  display: flex;
  align-items: flex-end;
  padding: px-to-rem(22px) px-to-rem(42px);
  border-radius: $border-radius;
  background: white;

  > div {
    flex: 1;
  }
}

.statistics-card__label {
  margin-bottom: px-to-rem(14px);
  @include font-size(14px);
  opacity: 0.5;
}

.statistics-card__number {
  @include font-size(36px);
  color: $text-secondary;
  font-weight: bold;
  line-height: 1;
  margin-bottom: 0;
  margin-right: 18px;
}

.statistics-card__chart {
  max-width: 260px;
  height: 50px;
  background-repeat: no-repeat;
  background-position: center right;
  background-size: contain;
}
