@mixin alert-variant($background, $border, $color) {
  color: $color;
  @include gradient-bg($background);
  // border-color: $border;
  box-shadow: 0px 6px 20px rgba($color, 0.08);

  hr {
    border-top-color: darken($border, 5%);
  }

  .alert-link {
    color: darken($color, 10%);
  }
}
