.section {
  padding-top: $section-padding-y;
  padding-bottom: $section-padding-y;
  background-color: $light;

  & + .section {
    background-color: #fff;
  }

  &.contact-section {
    .container {
      position: relative;
      &:before {
        content: "";
        pointer-events: none;
        position: absolute;
        left: 0;
        right: 0;
        top: -10px;
        bottom: -10px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
      }
    }
  }
}

.no-webp .section.contact-section .container:before {
  background-image: url(../images/world-map.png);
}
.webp .section.contact-section .container:before {
  background-image: url(../images/world-map.webp);
}

.section__lead {
  margin-bottom: px-to-rem(40px);
  text-align: center;

  h1 {
    @include font-size(40px);
    color: var(--primary-color, $primary);
    margin-bottom: 0;
  }

  .hr {
    display: inline-block;
    width: 70px;
    height: 4px;
    background-color: $secondary;

    position: relative;
    bottom: 7px;
  }

  p {
    max-width: 520px;
    margin: 4px auto 0;
    // break lines at new lines character
    white-space: pre-line;
  }
}
