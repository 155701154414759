.fade {
  @include transition($transition-fade);

  &:not(.show) {
    opacity: 0;
  }
}

.collapse {
  &:not(.show) {
    display: none;
  }
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  @include transition($transition-collapse);
}

.dashboard-page-enter {
  opacity: 0;
  @media screen and (pointer: fine) {
    transform: translateX(100px);
    // customize dashboard home and edit-profile transform
    &.dashboard-home, &.edit-profile {
      transform: translateX(-100px);
    }
  }
}

.dashboard-page-enter-active {
  opacity: 1;
  transform: translateX(0) !important; // override customized transform pages
  backface-visibility: hidden;
  @include transition(transform 300ms, opacity 240ms);
}
