// multi select depends on custom forms (checkboxes) and list-group styles
.multi-select {
  position: relative;
}
.multi-select-group {
  @extend .list-group;
  position: absolute;
  z-index: 10;
  left: 0;
  right: 0;
  top: calc(100% - 1px);
  border-radius: $border-radius;
  box-shadow: 0px 6px 20px #c1cfd933;
}

.multi-select-item {
  @extend .list-group-item;
  @extend .list-group-item-action;
  @extend .custom-checkbox;
  .icon {
    @extend .custom-control-label;
    display: inline-block;
    width: calc(100% - 16px);
  }

  &[aria-selected="true"] .icon {
    &:before {
      color: $custom-control-indicator-checked-color;
      border-color: $custom-control-indicator-checked-border-color;
      @include gradient-bg($custom-control-indicator-checked-bg);
      @include box-shadow($custom-control-indicator-checked-box-shadow);
    }
    &:after {
      background-image: escape-svg($custom-checkbox-indicator-icon-checked);
    }
  }
}

.multi-select-toggle {
  padding-left: $btn-padding-x + $custom-select-indicator-padding;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  background: $custom-select-bg $custom-select-background;
  text-align: start;
  .muted {
    opacity: 0.6;
  }
}
