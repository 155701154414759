.icon {
  width: 1rem;
  height: 1rem;
}

.loading-icon {
  @extend .icon;
  animation: spin 2s infinite linear;
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
