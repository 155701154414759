// login page
.login-page {
  display: flex;
  align-items: stretch;
}
.login-page__bg {
  min-height: 100vh;
  flex: 1 1 50%;
  width: 50%;
  background-image: url(../images/login-bg.webp);
  background-size: cover;
  background-position: center;

  @include media-breakpoint-down("md") {
    display: none;
  }
}

.login-page__content {
  flex: 1 1 50%;
  width: 50%;
  min-height: 100vh;
  text-align: center;
  background-color: $light;
  padding: $login-padding-y $login-padding-x $footer-height;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.login-page__logo {
  .logo {
    width: auto;
    height: 168px;
  }
  p {
    @include font-size(20px);
    margin-top: px-to-rem(15px);
    margin-bottom: px-to-rem(48px);
  }
}

.login-page__footer {
  position: absolute;
  height: $footer-height;
  // center vertically
  line-height: $footer-height;
  left: 0;
  right: 0;
  bottom: 0;
}

.login-form {
  max-width: 400px;
  margin: 0 auto;
  text-align: start;
}

// dashboard
.dashboard {
  background: $light;
  min-height: 101vh; // 101 to force the appearance of the scroll

  // for my absolute positioned footer
  position: relative;
  padding-bottom: $footer-height;

  .pagination {
    justify-content: flex-end;
  }
}

.dashboard__actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  .btn-primary {
    flex-shrink: 0;
  }

  @include media-breakpoint-down("xs") {
    .btn-primary {
      margin-bottom: 10px;
    }
    .form-inline {
      flex-basis: 100%;
    }
    #categories {
      width: 100%;
    }
  }
}

// select input
#categories {
  color: $secondary;
  background-color: white;
  width: 140px;
  text-overflow: ellipsis;
}

.dashboard__search-form {
  position: relative;
  flex: 1;
  &:after {
    opacity: 0.6;
    content: escape-svg($search-icon);
    display: inline-block;
    width: 14px;
    height: 14px;
    position: absolute;
    // center vertically
    top: calc(50% - 10px);
    left: 12px;
    pointer-events: none;
  }
  .form-control {
    padding-left: 32px;
    background: white;
  }
}

.lawyers-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  grid-gap: 1rem;
}

.dashboard__footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: $footer-height;
  // center vertically
  line-height: $footer-height;
  text-align: center;
  background: white;
  box-shadow: 0px 0px 10px #0000000d;
}

.advanced__title-row {
  @include media-breakpoint-up("sm") {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

// Error404
.error-404 {
  min-height: 100vh;
  display: flex;
  align-items: center;
  text-align: center;
}

.error-404__img {
  width: 600px;
  max-width: 100%;
}

// lawyer page
.lawyer {
  --primary-color: #{$primary};
  --primary-color-light: #{lighten($primary, 10%)};
  --primary-color-dark: #{darken($primary, 10%)};
}

.text-primary {
  color: var(--primary-color) !important;
}
