// stylelint-disable selector-no-qualifying-type

//
// Base styles
//

.btn {
  display: inline-block;
  font-family: $btn-font-family;
  font-weight: $btn-font-weight;
  color: $body-color;
  text-align: center;
  white-space: $btn-white-space;
  vertical-align: middle;
  cursor: if($enable-pointer-cursor-for-buttons, pointer, null);
  user-select: none;
  background-color: transparent;
  border: $btn-border-width solid transparent;
  @include button-size(
    $btn-padding-y,
    $btn-padding-x,
    $btn-font-size,
    $btn-line-height,
    $btn-border-radius
  );
  @include transition($btn-transition);

  @include hover() {
    color: $body-color;
    text-decoration: none;
  }

  &:focus,
  &.focus {
    outline: 0;
    box-shadow: $btn-focus-box-shadow;
  }

  // Disabled comes first so active can properly restyle
  &.disabled,
  &:disabled {
    opacity: $btn-disabled-opacity;
    @include box-shadow(none);
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    @include box-shadow($btn-active-box-shadow);

    &:focus {
      @include box-shadow($btn-focus-box-shadow, $btn-active-box-shadow);
    }
  }
}

// Future-proof disabling of clicks on `<a>` elements
a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

//
// Alternate buttons
//

@each $color, $value in $theme-colors {
  .btn-#{$color} {
    @include button-variant($value, $value);
    // override default style for primary buttons
    @if $color == "primary" {
      min-width: 120px;
      padding-left: $btn-padding-x * 2;
      padding-right: $btn-padding-x * 2;
    }
    // override default style for primary and success buttons
    @if $color == "primary" or $color == "success" {
      color: white;
      .loading-icon {
        fill: white;
      }
    }
  }
}

@each $color, $value in $theme-colors {
  .btn-outline-#{$color} {
    @include button-outline-variant($value);

    // override default style for primary buttons
    @if $color == "primary" {
      min-width: 120px;
      padding-left: $btn-padding-x * 2;
      padding-right: $btn-padding-x * 2;
    }
    // override default style for primary and success buttons
    @if $color == "primary" or $color == "success" {
      &:hover {
        color: white;
      }
    }
  }
}

//
// Link buttons
//

// Make a button look and behave like a link
.btn-link {
  font-weight: $font-weight-normal;
  color: $link-color;
  text-decoration: $link-decoration;

  @include hover() {
    color: $link-hover-color;
    text-decoration: $link-hover-decoration;
  }

  &:focus,
  &.focus {
    text-decoration: $link-hover-decoration;
    box-shadow: none;
  }

  &:disabled,
  &.disabled {
    color: $btn-link-disabled-color;
    pointer-events: none;
  }

  // No need for an active state here
}

//
// Button Sizes
//

.btn-lg {
  @include button-size(
    $btn-padding-y-lg,
    $btn-padding-x-lg,
    $btn-font-size-lg,
    $btn-line-height-lg,
    $btn-border-radius-lg
  );
  .loading-icon {
    width: 1.2rem;
    height: 1.2rem;
  }
}

.btn-sm {
  @include button-size(
    $btn-padding-y-sm,
    $btn-padding-x-sm,
    $btn-font-size-sm,
    $btn-line-height-sm,
    $btn-border-radius-sm
  );
}

//
// Block button
//

.btn-block {
  display: block;
  width: 100%;

  // Vertically space out multiple block buttons
  + .btn-block {
    margin-top: $btn-block-spacing-y;
  }
}

// Specificity overrides
input[type="submit"],
input[type="reset"],
input[type="button"] {
  &.btn-block {
    width: 100%;
  }
}

.btn-dynamic {
  min-width: 120px;
  padding-left: $btn-padding-x * 2;
  padding-right: $btn-padding-x * 2;
  $primary-color: var(--primary-color, $primary);
  $primary-color-dark: var(--primary-color-dark, darken($primary, 10%));
  @include button-variant(
    $primary-color,
    $primary-color,
    $primary-color-dark,
    $primary-color-dark,
    $primary-color-dark,
    $primary-color-dark
  );
}


// floating buttons
.ms-call-btn {
  left: 15px;
  bottom: 160px;
}

.ms-whats-btn {
  left: 15px;
  bottom: 60px;
}

.ms-call-btn, .ms-whats-btn {
  display: flex;
  position: fixed;
  outline: 0;
  cursor: pointer;
  z-index: 9999;
  text-decoration: none;
}

.ms-call-icon {
  border: 1px solid #2d6ac7;
  background-color: #fff
}

.ms-whats-icon {
  background-color: #fff;
}

.ms-call-icon, .ms-whats-icon {
  display: inline-block;
  position: relative;
  width: 65px;
  height: 64px;
  line-height: 66px;
  text-align: center;
  border-radius: 50%;
  background-color: #fff;
  -webkit-box-shadow: 1px 1px 6px 0 rgba(68,68,68,0.71);
  -moz-box-shadow: 1px 1px 6px 0 rgba(68,68,68,.705);
  box-shadow: 1px 1px 6px 0 rgba(68,68,68,0.71);
  animation: 2s ease-in-out infinite call-rotate;
}

.ms-left {
  padding: 0 20px 0 8px;
  margin-right: -15px;
  order: 1;
}

.ms-call-txt {
  background-color: #2d6ac7;
  color: #fff;
}

.ms-whats-txt {
  background-color: #2ab540;
  color: #fff;
}

.ms-call-txt, .ms-whats-txt {
  font-size: 15px;
  font-weight: 600;
  display: inline-block;
  line-height: 32px;
  border-radius: 10px;
  height: 35px;
  margin-top: 15px;
  width: 100px;
  text-align: center;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 0 5px rgba(0,0,0,.1);
}

@keyframes call-rotate {
  0%,100%,50% {
      -webkit-transform: rotate(0) scale(1) skew(1deg);
      transform: rotate(0) scale(1) skew(1deg)
  }

  10%,30% {
      -webkit-transform: rotate(-25deg) scale(1) skew(1deg);
      transform: rotate(-25deg) scale(1) skew(1deg)
  }

  20%,40% {
      -webkit-transform: rotate(25deg) scale(1) skew(1deg);
      transform: rotate(25deg) scale(1) skew(1deg)
  }
}