// OVERRIDE BOOTSTRAP VARIABLES OR DEFINE NEW ONES

// fonts
$font-family-sans-serif: "Droid Arabic Kufi", -apple-system, BlinkMacSystemFont,
  "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
  "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$base-size: 16px; // used in px-to-rem function
$body-color: #484848;
$text-secondary: #727272;
$font-size-base: 1rem; // Assumes the browser default, typically `16px`

// colors
$primary: #008755;
$green-light: #dfffe0;
$secondary: #c1a73d;
$light: #fafafa;
$divider-color: #ededed;
$success: $primary;

// grid
$grid-gutter-width: 30px;

// carouse
$carousel-control-icon-width: 50px;
$carousel-control-color: #f5f5f5;
$carousel-control-border: #fff;
$carousel-indicator-transition: opacity 0.5s ease, transform 0.4s ease;
$carousel-control-transition: background-color 0.15s ease, stroke 0.15s ease;
$carousel-indicator-width: 10px;
$carousel-indicator-spacer: 5px;
$carousel-indicator-active-bg: #dff7ff;

// service
$service-icon-width: 150px;

// buttons
$border-radius: px-to-rem(10px);

// info
$info-icon-width: px-to-rem(19px);

// forms
$input-bg: $light;
$input-border-color: #d6d6d6;
$input-focus-border-color: lighten($primary, 19%);
$input-btn-focus-color: rgba($primary, 0.12);
$input-btn-focus-box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.6);
$dashboard-input-bg: #f5f7f9;
$dashboard-input-border-color: #e6eaf0;
$input-btn-padding-y: 0.5rem;
$input-btn-padding-y-lg: 0.75rem;
$input-btn-font-size-lg: $font-size-base;
$search-icon: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.2 19.2"><defs><style>.a{fill:#{lighten(#3a3a3c, 24)};}</style></defs><path class="a" d="M15.24,13.476a8.132,8.132,0,0,0,1.68-5.009A8.424,8.424,0,0,0,8.52,0,8.526,8.526,0,0,0,0,8.467a8.526,8.526,0,0,0,8.52,8.467,8,8,0,0,0,5.04-1.67l3.6,3.578a1.165,1.165,0,0,0,1.68,0,1.148,1.148,0,0,0,0-1.67Zm-6.72.954A6.026,6.026,0,0,1,2.4,8.467a6.12,6.12,0,0,1,12.24,0A6.026,6.026,0,0,1,8.52,14.43Z"/></svg>');
$input-placeholder-color: lighten($text-secondary, 25);
$custom-select-bg-size: 10px 8px; // In pixels because image dimensions
$custom-select-indicator-color: $secondary;
$custom-select-indicator: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.197 10.014"><g transform="translate(34.227 -20.69) rotate(90)"><path d="M0,0,7.531,7.6,15.369,0" transform="translate(22.104 32.813) rotate(-90)" fill="none" stroke="#{$custom-select-indicator-color}" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/></g></svg>');

// navbar
$navbar-light-active-color: $primary;
$navbar-light-toggler-border-color: transparent;
$navbar-padding-x: $grid-gutter-width / 2; // to work better with grid system
$navbar-padding-y: 0.75rem;
$navbar-nav-link-padding-x: 20px;
$navbar-light-color: $body-color;

$section-padding-y: px-to-rem(84px);

// layout
$login-padding-x: px-to-rem(15px);
$login-padding-y: px-to-rem(24px);
$footer-height: 80px;

// cards
$card-spacer-y: 1rem;
$card-border-color: #dbede9;

// pagination
$pagination-border-color: transparent;
$pagination-color: rgba($text-secondary, 0.6);
$pagination-hover-color: $text-secondary;

// type
$h1-font-size: px-to-rem(36px);

// icons
$file-upload: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60.235 56"><g fill="#b2b2b2" transform="translate(0 0)"><path d="M50.824,0H9.412A9.422,9.422,0,0,0,0,9.411V42.353a9.422,9.422,0,0,0,9.412,9.412H41.529a2.353,2.353,0,0,0,0-4.706H32.863l-5.41-7.018L44.6,18.235,56.138,30.99a2.353,2.353,0,0,0,4.1-1.579v-20A9.422,9.422,0,0,0,50.824,0ZM55.53,23.3,46.216,13.009a2.353,2.353,0,0,0-3.595.125L24.489,36.2,18.334,28.21a2.353,2.353,0,0,0-1.864-.916h0a2.353,2.353,0,0,0-1.864.918L9.427,34.944a2.353,2.353,0,0,0,3.73,2.869l3.315-4.31L26.922,47.059H9.412a4.711,4.711,0,0,1-4.706-4.706V9.411A4.711,4.711,0,0,1,9.412,4.706H50.824A4.711,4.711,0,0,1,55.53,9.411Zm0,0"/><path d="M87.059,72a7.059,7.059,0,1,0,7.059,7.059A7.067,7.067,0,0,0,87.059,72Zm0,9.412a2.353,2.353,0,1,1,2.353-2.353A2.356,2.356,0,0,1,87.059,81.412Zm0,0" transform="translate(-70.588 -63.53)"/><path class="a" d="M366.585,289.757l-.021-.021a5.865,5.865,0,0,0-8.322-.01l-4.543,4.481a2.353,2.353,0,0,0,3.3,3.35l3.041-3v13.206a2.353,2.353,0,1,0,4.706,0V294.63l2.91,3.006a2.353,2.353,0,1,0,3.381-3.273Zm0,0" transform="translate(-311.47 -254.118)"/></g></svg>');
$external-link: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" fill="white"><path d="M22 14.5v5c0 2.484-2.016 4.5-4.5 4.5h-13c-2.484 0-4.5-2.016-4.5-4.5v-13c0-2.484 2.016-4.5 4.5-4.5h11c0.281 0 0.5 0.219 0.5 0.5v1c0 0.281-0.219 0.5-0.5 0.5h-11c-1.375 0-2.5 1.125-2.5 2.5v13c0 1.375 1.125 2.5 2.5 2.5h13c1.375 0 2.5-1.125 2.5-2.5v-5c0-0.281 0.219-0.5 0.5-0.5h1c0.281 0 0.5 0.219 0.5 0.5zM28 1v8c0 0.547-0.453 1-1 1-0.266 0-0.516-0.109-0.703-0.297l-2.75-2.75-10.187 10.187c-0.094 0.094-0.234 0.156-0.359 0.156s-0.266-0.063-0.359-0.156l-1.781-1.781c-0.094-0.094-0.156-0.234-0.156-0.359s0.063-0.266 0.156-0.359l10.187-10.187-2.75-2.75c-0.187-0.187-0.297-0.438-0.297-0.703 0-0.547 0.453-1 1-1h8c0.547 0 1 0.453 1 1z"></path></svg>');

// modals
$modal-content-border-radius: $border-radius;

// dropdown
$dropdown-border-color: rgba(black, 0.1);
$dropdown-link-active-bg: $secondary;

// alerts
$alert-padding-y: 1rem;
