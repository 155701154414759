.header__logo {
  display: inline-block;
  width: auto;
  height: 3rem;
  margin-left: 10px;
}

.header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 20;
  background: white;
  box-shadow: 0px 2px 6px #0000000a;

  .navbar-brand {
    max-width: 82%;
  }

  @include media-breakpoint-down("xs") {
    .navbar-brand {
      @include font-size(16px);

      > span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}

.dashboard-header {
  .navbar {
    position: relative;
    z-index: 1;
    box-shadow: 0px 2px 6px #0000000a;
    background: white;
  }
}

.dashboard-header__btn-group {
  position: relative;
  display: flex;
  align-items: center;
  margin-right: auto;

  .dropdown-toggle {
    color: $body-color;
  }

  // customize divider
  .divider {
    margin-left: 0;
    margin-right: 0;

    &:after {
      background: rgba($text-secondary, 0.2);
      top: 6px;
      bottom: 6px;
    }
  }

  // logout btn
  .btn {
    @include font-size(16px);
    color: $body-color;
    white-space: nowrap; // no wrap for icon
    &.active {
      color: $primary;
      font-weight: bold;
    }

    &:last-child {
      padding-left: 0;
    }
  }

  // btn icon
  .icon {
    width: 18px;
    height: 18px;
    margin-right: 6px;
    vertical-align: sub;
  }
}

.dashboard-header__search-form {
  margin-right: 20px;
  width: 60%;
  max-width: 720px;
  position: relative;

  // search icon
  &:before {
    content: escape-svg($search-icon);
    display: inline-block;
    width: 18px;
    height: 18px;
    position: absolute;
    // center vertically
    top: calc(50% - 9px);

    right: 14px;
    pointer-events: none;
  }

  .form-control {
    // form search icon
    padding-right: 40px;
  }
}

@include media-breakpoint-down("sm") {
  .dashboard-header__btn-group {
    margin-top: px-to-rem(10px);
    display: block;

    .divider {
      display: none;
    }

    .btn,
    .dropdown-toggle {
      // take full width
      display: inline-block;
      width: 100%;
      text-align: start;
    }
  }
  .dashboard-header__search-form {
    width: auto;
    margin: px-to-rem(16px) 0 px-to-rem(10px) 0;
  }
}
